<template>
    <div class="order-detail-box">
        
        <van-divider class="my-divider" content-position="left" style="margin: 10px;" dashed>
            <van-icon v-if="del && (details && details.length)" name="delete-o" class="icon-btn" size="18" @click="del" />
        {{title}}
        </van-divider>
        <div class="detail-list inner-box">
            <div class="" v-if="!details || !details.length">
                
            </div>
            <div class="detail-item" v-for="(item, index) in details" :key="index">
                <van-row>
                  <van-col span="5" class="my-col1">物料编码</van-col>
                  <van-col span="19" class="my-col2 align-right">{{item.model}}</van-col>
                </van-row>
                <van-row class="item-line" v-if="item.category==2">
                  <van-col span="5" class="my-col1">物料名称</van-col>
                  <van-col span="19" class="my-col2 align-right">{{item.name}}</van-col>
                </van-row>
                <van-row class="item-line" v-if="item.category==2">
                  <van-col span="5" class="my-col1">柴油机</van-col>
                  <van-col span="19" class="my-col2 align-right">{{item.engine}}</van-col>
                </van-row>
                <van-row class="item-line">
                  <van-col span="5" class="my-col1">采购数量</van-col>
                  <van-col span="19" class="my-col2 item-num align-right">{{item.num}}</van-col>
                </van-row>
                <van-row class="item-line" v-if="item.remark">
                    <!-- <van-col span="5">备注</van-col> -->
                   <van-col span="24" class="my-col2">备注：{{item.remark}}</van-col>
                </van-row>
            </div>
            
        </div>
    </div>
    
</template>

<script>
    export default {
        props: ['details', 'del', 'title']
    }
</script>

<style scoped>
    .order-detail-box{
        position: relative;
    }
    .my-divider{
        position: relative;
    }
    .icon-btn{
        position: absolute;
        top: 2px;
        right: 20px;
    }
    .inner-box{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .detail-item{
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 14px;
        /* padding: 0.8rem; */
        line-height: 26px;
        border-radius: 3px;
        /* font-weight: bold; */
        -webkit-box-shadow: 0 0 1px 1px #ddd ;
        box-shadow: 0 0 1px 1px #ddd  ;
        text-align: left;
        color: #333;
    }
    .detail-item .my-col1, .detail-item .my-col2{
        padding: 6px 6px;
    }
    .detail-item .my-col1{
        font-weight: bold;
    }
    .detail-item .btn-box{
        display: flex;
        justify-content: space-around;
    }
    .detail-item .align-right{
        text-align: right;
    }
    .detail-item .item-num{
        /* font-weight: bold; */
        /* font-size: 16px; */
    }
    
    .item-line{
        border-top: 1px dashed #d2d2d2;
    }
</style>