<template>
    <div class="list-container">
        <div class="list-del"
            v-if="del && details && details.length"
            @click="del && del()">
            <van-icon name="close" size="30" color="#ED6A0C" />
        </div>
        <div class="" ref="list">
            
        </div>
    </div>
</template>

<script>
    import { drawGenset, drawEngine } from '@/utils/common.js';
    export default {
        props: ['details', 'category', 'del'],
        mounted() {
            //console.log('mounted', this.details);
            if(this.category == 2){
                drawGenset(this.details, this.$refs.list);
            }else if(this.category == 1){
                drawEngine(this.details, this.$refs.list);
            }
        },
        
        watch:{
            details:{
                handler(list) {
                // do something, 可使用this
                   if(this.category == 2){
                       drawGenset(list, this.$refs.list);
                   }else if(this.category == 1){
                       drawEngine(list, this.$refs.list);
                   }
                },
                deep:true
            }
        },
    }
</script>

<style scoped>
    .list-container{
        position: relative;
    }
    .list-del{
        position: absolute;
        top: -13px;
        right: 6px;
    }
</style>