import { render, staticRenderFns } from "./OrderDetailConfig.vue?vue&type=template&id=22aa986c&scoped=true&"
import script from "./OrderDetailConfig.vue?vue&type=script&lang=js&"
export * from "./OrderDetailConfig.vue?vue&type=script&lang=js&"
import style0 from "./OrderDetailConfig.vue?vue&type=style&index=0&id=22aa986c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22aa986c",
  null
  
)

export default component.exports